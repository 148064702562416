import React, { useContext } from 'react'
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography, Grid, Icon, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { FavoriteBorder, FavoriteOutlined } from '@material-ui/icons'
import MyButton from '../custom_mui_components/MyButton'
import { ApiContext } from '../../context/ApiContext'
import { Container, Row, Col } from 'react-bootstrap'
import { ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        //boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: 0,
        borderWidth: 0,
        userSelect: 'none',
        backgroundColor: 'transparent',
    },
    cardFavriteIcon: {
        position: 'absolute',
        right: '0px'
    },
    cardTitle: {
        //color: '#bf9410',
        color: 'white',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontSize: '3.0rem',
        fontWeight: '500',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.0rem',
            fontWeight: '400'
        },
    },
    cardSubTitle: {
        color: 'white',
        fontSize: '1.2rem',
        fontWeight: '400',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
            fontWeight: '400'
        },
    },
    cardBody: {
        //color: '#bf9410',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            //fontSize: '0.7rem',
            //fontWeight: '400'
        },
    },
    arrow: {
        //color: '#bf9410',
        color: 'white',
        width: '30px',
        height: '25vh',
        //fontSize: '2.4rem',
        //fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '12vh',
            //fontSize: '1.2rem',
            //fontWeight: '500'
        },
    },
    overlay: {
        position: 'absolute',
        left: '0px',
        top: '50%',
        //bottom: '10px',
        width: '100vw',
        //transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent'
    },
    cardMedia: {
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        width: '100vw',
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px',
        }
    },
    cardText: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
}))

function Heading({ title, link = null, subTitle = "", body = null, background = "", color = "white" }) {
    const { staticLinks } = useContext(ApiContext)
    const { headingDark } = useContext(ApiContext)
    const classes = useStyles()
    const history = useHistory()
    return (
        <Card className={classes.cardRoot}>
            <div onClick={link != null ? (e) => { history.push(link) } : null}>
                {false &&
                    <CardMedia className={classes.cardMedia}>
                    <img className="w-100 h-100" src={background} alt={title} style={{ display: "block" }} />
                    </CardMedia>
                }
                <div className={classes.cardMedia} style={background != "" ? background : (staticLinks ?.find(s => window.location.pathname == "/" ? window.location.pathname.indexOf(s.id) >= 0 : (s.id != "/" && window.location.pathname.indexOf(s.id) >= 0)) ?.heading_background && { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(${staticLinks ?.find(s => s.id != "/" && window.location.pathname.indexOf(s.id) >= 0) ?.heading_background})` })}>

                    <Box mx={2} my={1}>
                        <h1 className={classes.projectsTitle} style={{ color: headingDark ? '#ffffff' : '#000000' }}>{title}</h1>
                        {body != null && body}
                    </Box>

                </div>
            </div>
        </Card>
    )
}

export default Heading