import React from 'react'
import {
    BrowserRouter as Router,
    Route, Switch, Link, useLocation
} from 'react-router-dom'
import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { ApiContext } from './context/ApiContext'
import { CircularProgress } from '@material-ui/core'
import Routes from './Routes'



function App() {
    const { staticLinks, loadStaticLinks } = useContext(ApiContext)
    const { pageBackground, setPageBackground } = useContext(ApiContext)
    useEffect(() => {
        if (!staticLinks) {
            loadStaticLinks()
            console.log("Loading Static Links")
            //console.log(staticLinks)
        } else {
            console.log("Static Links already loaded")
            //console.log(staticLinks)
        }
    }, [])
    useEffect(() => {
        if (staticLinks) {
            console.log("Loaded Static Links")
            console.log(staticLinks)
            if (staticLinks ?.find(s => s.id == window.location.pathname)) {
                const pageStyle = {}
                console.log("In staticLinks")
                if (staticLinks ?.find(s => s.id == window.location.pathname) ?.page_background) {
                    pageStyle.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(${staticLinks ?.find(s => s.id == window.location.pathname) ?.page_background})`
                }
                if (staticLinks ?.find(s => s.id == window.location.pathname) ?.page_background_color) {
                    pageStyle.backgroundColor = staticLinks ?.find(s => s.id == window.location.pathname) ?.page_background_color
                } else {
                    pageStyle.backgroundColor = 'grey'
                }
                setPageBackground(pageStyle)
            } else {
                console.log("Not in staticLinks")
                setPageBackground(null)
            }
        }
    }, [staticLinks])
    return (
            <>
            {!staticLinks ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div> :
                <Router> {/* forceRefresh={true} */}
                    <Routes />
                </Router>
                    }
            </>
            )
}

export default App

