import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Box, Hidden, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Menu } from '@material-ui/icons';
import logo_light from '../../assets/logo_light.png';
import logo_dark from '../../assets/logo_dark.png';
import { Link } from 'react-router-dom';
import { ApiContext } from '../../context/ApiContext'

const useStyles = makeStyles((theme) => ({
    navRoot: {
        padding: '18px 40px',
        backgroundColor: '#121212',
        transition: '0.3s',
        boxShadow: '0 5px 12.09px 0.91px rgba(34, 34, 34, 0.08)',
        [theme.breakpoints.down('sm')]: {
            //padding: '5px 20px',
        }
    },
    p0: {
        padding: '0'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '5px',
        [theme.breakpoints.up('md')]: {
            marginRight: '25px'
        }
    },
    navItemList: {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'row',
        margin: '0',
        padding: '0',
        boxSizing: 'border-box',
        '&>li': {
            padding: '5px 15px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    navMenuItem: {
        color: 'inherit', //#888484
        fontSize: '14px',
        fontWeight: '700',
        padding: '3px 5px',
        textDecoration: 'none',
        position: 'relative',
        zIndex: '1102',
        transition: '0.3s',
        '&::before': {
            content: '""',
            zIndex: '-1',
            display: 'block',
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            //backgroundColor: '#f8ecc7',
            width: '0px',
            transition: '0.3s'
        },
        '&:hover': {
            color: '#1b1c21',
            '&::before': {
                width: '100%'
            },
            //textDecoration: 'underline',
        }
    },
    list: {
        width: 250
    },
    link: {
        color: '#4f4f4f',
        textDecoration: 'none'
    }
}));

//function HideOnScroll(props) {

//    const { children, window } = props;
//    // Note that you normally won't need to set the window ref as useScrollTrigger
//    // will default to window.
//    // This is only being set here because the demo is in an iframe.
//    const trigger = useScrollTrigger({ target: window ? window() : undefined });

//    return (
//        <Slide appear={false} direction="down" in={!trigger}>
//            {children}
//        </Slide>
//    );
//}


function Navbar() {
    const [drawerOpen, serDrawerOpen] = useState(false)
    //const { staticLinks, loadStaticLinks } = useContext(ApiContext)
    const { staticLinks } = useContext(ApiContext)
    const { logoDark } = useContext(ApiContext)
    const trigger = useScrollTrigger({
        disableHysteresis: true
    })
    const classes = useStyles();
    /*useState(() => {
        console.log(staticLinks)
        loadStaticLinks()
        console.log(staticLinks)
    }, [])*/

    /*const { apiKey, setApiKey } = useContext(ApiContext)
    useState(() => {
        console.log("API Key")
        console.log(apiKey)
    }, [apiKey])

    console.log(staticLinks)
    */
    return (
        <>
            <Drawer anchor="left" open={drawerOpen} onClose={() => { serDrawerOpen(false) }}>
                <List className={classes.list}>
                    {staticLinks != null ? staticLinks.map((d) => (<>
                        {
                            d.routable ? <Link className={classes.link} to={d.id}>
                            <ListItem button>
                                    <ListItemText primary={d.text} style={((window.location.pathname == "/" && d.id == "/") || (window.location.pathname != "/" && window.location.pathname.indexOf(d.id) >= 0)) ? { textDecoration: 'underline', textTransform: 'uppercase' } : {}} />
                            </ListItem>
                            </Link> : <></>
                        }</>
                        )) : <></>}
                </List>
            </Drawer>
            <AppBar position="fixed" className={classes.navRoot}
                style={{ backgroundColor: trigger ? (logoDark ? '#ffffff' : '#ffffff') : 'transparent', color: trigger ? '#888484' : (logoDark ? '#ffffff' : '#ffffff') }}>
                <Toolbar className={classes.p0}>
                    <div className={classes.logoContainer}>
                        <Link to="/">
                            <img src={trigger ? (logoDark ? logo_dark : logo_light) : (logoDark ? logo_light : logo_dark)} alt="logo" height="60" />
                        </Link>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <ul className={classes.navItemList}>
                            {staticLinks != null ? staticLinks.map((d) => (
                                <>
                                    {d.routable ? 
                                    <li>
                                            <Link to={d.id} className={classes.navMenuItem} style={((window.location.pathname == "/" && d.id == "/") || (window.location.pathname != "/" && window.location.pathname.indexOf(d.id) >= 0)) ? { textDecoration: 'underline', textTransform: 'uppercase', color: trigger ? (logoDark ? '#000000' : '#000000') : (logoDark ? '#ffffff' : '#000000') } : { color: trigger ? (logoDark ? '#000000' : '#000000') : (logoDark ? '#ffffff' : '#000000')}}>{d.text}</Link>
                                    </li>
                                    : <></>
                                }
                                </>
                            )) : <></>}
                        </ul>
                    </div>
                    <Hidden mdUp>
                        <Box ml="auto">
                            <IconButton style={{ color: (logoDark ? '#ffffff' : '#000000') }} onClick={() => { serDrawerOpen(true) }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    </Hidden>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Navbar