import { Box, Grid, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect } from 'react'
import Slider from 'react-slick'
import Banner from '../widgets/Banner'
import Heading from '../widgets/Heading'
import CategoryCardGrid from '../widgets/sub_widgets/CategoryCardGrid'
import { ApiContext } from '../../context/ApiContext'
import Navbar from '../widgets/Navbar'
const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
        height: '100vh',
        minHeight: '0',
        }
    },
    container: {
        marginTop: '100px',
        //marginTop: '130px',
        //[theme.breakpoints.down('sm')]: {
        //    marginTop: '90px',
        //},
        //[theme.breakpoints.down('xs')]: {
        //    marginTop: '90px',
        //}
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        //paddingLeft: '5vw',
        //paddingRight: '5vw',
        position: 'absolute',
        height: '720vh',
        left: '0',
        right: '0',
        paddingLeft: '0px',
        paddingRight: '0px',
        //maxWidth: '25vw',
        //bottom: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
            //maxWidth: '50vw',
            //top: '40px',
            //bottom: '0'
        },
    }
}))


function ProjectCategoryCardsBanner() {
    const { categories, loadCategories, loadMoreCategories, categorySlidesToShow } = useContext(ApiContext)
    const classes = useStyles()
    const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 4,
        /*afterChange: (index) => {
            if (index + 4 >= categories.results.length) {
                loadMoreCategories()
            }
        },*/
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    /*afterChange: (index) => {
                        if (index + 4 >= categories.results.length) {
                            loadMoreCategories()
                        }
                    },*/
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    /*afterChange: (index) => {
                        if (index + 2 >= categories.results.length) {
                            loadMoreCategories()
                        }
                    },*/
                }
            },
        ]
    };

    useEffect(() => {
        loadCategories()
        document.title = "Portfolio"
        return () => document.title = 'Portfolio'
    }, [])
    return (
                <Container maxWidth={false} className={classes.container} disableGutters>
            {false && <Heading title="Portfolio" />}
                <Box pl={2} pr={2} className={classes.cardCarouselContainer}>
                    <div>
                        {categories != null ?
                                <>
                                    <Slider {...settings} slidesToShow={categorySlidesToShow}>
                                        {categories.results.map((d) => (
                                            <CategoryCardGrid image={d.thumbnail} title={d.heading} subTitle={d.subheading} id={d.id} key={d.id} />
                                        ))}
                                    </Slider>
                            </>
                            : <></>}
                    </div>
                    </Box>
                </Container>
    )
}

export default ProjectCategoryCardsBanner
