import React from "react"
import {
    Route, Switch, Link, useLocation
} from 'react-router-dom'
import ProjectDetails from './components/views/ProjectDetails'
import ContactUs from './components/views/ContactUs'
import Login from './components/views/Login'
//import CategoryList from './components/views/CategoryList'
//import CategoryDetails from './components/views/CategoryDetails'
import Home from './components/views/Home'
import ProjectCards from './components/views/ProjectCards'
import ProjectCardsGrid from './components/views/ProjectCardsGrid'
import ProjectCategoryCards from './components/views/ProjectCategoryCards'
import ProjectCategoryCardsBanner from './components/views/ProjectCategoryCardsBanner'
import BannerContent from './components/views/BannerContent'
import StaticContent from './components/views/StaticContent'
import Navbar from './components/widgets/Navbar'
import Photo360View from './components/views/Photo360View'
import ProjectTours from './components/views/ProjectTours'

import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { ApiContext } from './context/ApiContext'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        //backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(/uploads/images/map_gallery_img_1_CqBW43M.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '720px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative'
    },
}))

function Routes(props) {
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()
    const { staticLinks, loadStaticLinks } = useContext(ApiContext)
    const { pageBackground, setPageBackground } = useContext(ApiContext)
    const { logoDark, setLogoDark } = useContext(ApiContext)
    const { headingDark, setHeadingDark } = useContext(ApiContext)
    const { lockHeight, setLockHeight } = useContext(ApiContext)
    
    useEffect(() => {
        history.listen((loc) => {
            console.log(loc.pathname)
            if (staticLinks) {
                console.log(staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0))
                if (loc.pathname == "/") {
                    if (staticLinks ?.find(s => s.id == "/")) {
                        const pageStyle = {}
                        console.log("In staticLinks")
                        setLogoDark(staticLinks ?.find(s => s.id == "/") ?.logo_dark)
                        setHeadingDark(staticLinks ?.find(s => s.id == "/") ?.heading_dark)
                        setLockHeight(staticLinks ?.find(s => s.id == "/") ?.lock_height)
                        if (staticLinks ?.find(s => s.id == "/") ?.page_background) {
                            pageStyle.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(${staticLinks ?.find(s => s.id == "/") ?.page_background})`
                        }
                        if (staticLinks ?.find(s => s.id == "/") ?.page_background_color) {
                            pageStyle.backgroundColor = staticLinks ?.find(s => s.id == "/") ?.page_background_color
                        } else {
                            pageStyle.backgroundColor = 'white'
                        }
                        setPageBackground(pageStyle)
                    } else {
                        console.log("Not in staticLinks")
                        setPageBackground(null)
                    }
                } else {
                    if (staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0)) {
                        const pageStyle = {}
                        console.log("In staticLinks")
                        setLogoDark(staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.logo_dark)
                        setHeadingDark(staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.heading_dark)
                        setLockHeight(staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.lock_height)
                        if (staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.page_background) {
                            pageStyle.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(${staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.page_background})`
                        }
                        if (staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.page_background_color) {
                            pageStyle.backgroundColor = staticLinks ?.find(s => s.id != "/" && loc.pathname.indexOf(s.id) >= 0) ?.page_background_color
                        } else {
                            pageStyle.backgroundColor = 'white'
                        }
                        setPageBackground(pageStyle)
                    } else {
                        console.log("Not in staticLinks")
                        setPageBackground(null)
                    }
                }
                //setPageBackground(staticLinks ?.find(s => s.id == loc.pathname) ?.page_background ? {  } : { backgroundColor: 'grey' })
            } else {
                console.log("No Links")
            }
        })
    }, [history])

    useEffect(() => {
        console.log(pageBackground)
        console.log(logoDark)
        console.log(headingDark)
        console.log(lockHeight)
    }, [pageBackground, logoDark, headingDark, lockHeight])

    return (
        <>
            <Navbar />
            <div className={lockHeight || window.location.pathname == "/" ? classes.projectCardRoot : null} style={pageBackground ? pageBackground : { backgroundColor: 'white' }}>
                            <Switch>
                                {/* TODO: remove this block for production */}
                                <Route exact path="/links/:id">
                                    <BannerContent />
                                </Route>
                                <Route exact path="/content/:id">
                                    <StaticContent />
                                </Route>
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                {/* Till this line */}
                                <Route exact path="/portfolio2/:id">
                                    <ProjectCards />
                                </Route>
                                <Route exact path="/portfolio/:id">
                                    <ProjectCardsGrid />
                                </Route>
                                <Route exact path="/portfolio2">
                                    <ProjectCategoryCards />
                                </Route>
                                <Route exact path="/portfolio">
                                    <ProjectCategoryCardsBanner />
                                </Route>
                                <Route path="/project/:id">
                                    <ProjectDetails />
                                </Route>
                                {false && <Route path="/tour/:id">
                                    <ProjectTours />
                                </Route>}
                                <Route path="/tour/:project/:id">
                                    <Photo360View />
                                </Route>
                                <Route path="/tour/:project">
                                    <Photo360View />
                                </Route>
                                <Route path="/virtualtours/:project/:id">
                                    <Photo360View />
                                </Route>
                                <Route path="/virtualtours/:project">
                                    <Photo360View />
                                </Route>
                                <Route path="/contactus">
                                    <ContactUs />
                                </Route>
                                <Route path="/login">
                                    <Login />
                                </Route>
                        </Switch>
                        </div>
        </>
    );
}

export default Routes