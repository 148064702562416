import React, { useContext } from 'react'
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography, Grid, Divider } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Maximize } from '@material-ui/icons'
import MyButton from '../custom_mui_components/MyButton'
import { ApiContext } from '../../context/ApiContext'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        //boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: 0,
        borderWidth: 0,
    },
    cardFavriteIcon: {
        position: 'absolute',
        right: '0px'
    },
    cardTitle: {
        //color: '#bf9410',
        color: 'white',
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem',
            fontWeight: '400'
        },
    },
    cardSubTitle: {
        //textTransform: 'uppercase',
        color: 'white',
        fontSize: '1.4rem',
        //fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
            //fontWeight: '500'
        },
    },
    overlay: {
        position: 'absolute',
        left: '50%',
        top: 'calc(50% + 100px)',
        //bottom: '50px',
        transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent',
        userSelect: 'none',
        height: '50vh',
        width: '100vw',
        paddingLeft: '200px',
        paddingRight: '200px',
        //opacity: '1.0',
    },
    shadow: {
        position: 'absolute',
        left: '50%',
        top: 'calc(50% + 100px)',
        //bottom: '50px',
        transform: 'translate(-50%, -50%)',
        //left: '20px',
        //backgroundColor: 'grey',
        background: 'rgba(0, 0, 0, .4)',
        userSelect: 'none',
        height: '30vh',
        width: '100vw',
        paddingLeft: '200px',
        paddingRight: '200px',
        //opacity: '0.4',
    },
    status: {
        //border: 'solid 1px black',
        backgroundColor: 'transparent',
        color: 'white',
        height: '30px',
        font: 'normal normal normal 12px Helvetica, Arial, sans-serif',
        //padding: '10px',
        '-webkit-transform-origin': 'center center',
        '-webkit-transform': 'rotate(315deg)',
        '-moz-transform-origin': 'center center',
        '-moz-transform': 'rotate(315deg)',
        '-ms-transform-origin': 'center center',
        '-ms-transform': 'rotate(315deg)',
        '-o-transform-origin': 'center center',
        '-o-transform': 'rotate(315deg)',
        'transform-origin': 'center center',
        'transform': 'rotate(315deg)',
        position: 'absolute',
        top: '30px',
        left: '0px',
        userSelect: 'none',
    },
    image: {
        objectFit: 'cover', //contain
        height: '100vh',
        overflow: 'hidden',
        height: 'calc(100vh - 100px)',
        width: '100vw',
        [theme.breakpoints.down('xs')]: {
            objectFit: 'contain', //contain
            height: 'calc(100vh - 140px)',
            height: 'calc(-webkit-fill-available - 140px)',
            height: 'calc(-moz-available - 140px)',
        },
        display: 'block',
    },
    link: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        }
    },
    divider: {
        //transform: 'rotate(-180deg)'
        backgroundColor: 'white',
        height: '100%',
        width: '5px',
    },
    logo: {
        height: '20vh',
        width: '20vw',
        [theme.breakpoints.down('xs')]: {
            height: '20vh',
            width: '40vw',
        },
    },
    shadowCorner: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, .5)',
        bottom: '0px',
        right: '0px',
        userSelect: 'none',
        width: '25vw',
        [theme.breakpoints.down('xs')]: {
            bottom: '-20vh',
            right: '0px',
            width: '50vw',
        },
    },
    overlayCorner: {
        //left: '50%',
        //top: 'calc(50% + 100px)',
        //transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent',
        userSelect: 'none',
        //height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        //paddingLeft: '200px',
        //paddingRight: '200px',
        //opacity: '1.0',
    },
}))

function ShowcaseCard({ project }) {
    const classes = useStyles()
    const { toggleProjectInterest } = useContext(ApiContext)
    return (
        <Card className={classes.cardRoot}>
            {false && <div class={classes.status}>
                <Typography style={{ textAlign: 'center' }}>{project.status.title}</Typography>
            </div>}
            <CardMedia>
                <img className={classes.image} src={project.cover} alt={project.title} style={{ display: "block" }} />
                {project.logo && <div className={classes.shadowCorner}>
                    <div className={classes.overlayCorner}>
                        <img src={project.logo} alt="house" className={classes.logo} />
                    </div>
                </div>}
            </CardMedia>
            {false && <div className={classes.shadow}>
                <div className={classes.overlay}>
                    <Link to={'/project/' + project.id} className={classes.link}>
                        <Grid container spacing={2}>
                            <Grid item lg={4} md={4} sm={4} xs={4} justify="center">
                                <img src={project.logo} alt="house" className={classes.logo} />
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} alignItems="center">
                                <div className={classes.divider} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} justify="center">
                                <Typography variant="body2" className={classes.cardSubTitle} dangerouslySetInnerHTML={{ __html: project.writeup }} />
                            </Grid>
                        </Grid>
                    </Link>
                </div>
            </div>}
        </Card>
    )
}

export default ShowcaseCard