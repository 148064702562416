import React, { useContext } from 'react'
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { FavoriteBorder, FavoriteOutlined } from '@material-ui/icons'
import MyButton from '../custom_mui_components/MyButton'
import { ApiContext } from '../../context/ApiContext'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        //boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: 0,
        borderWidth: 0,
        margin: '1px',
    },
    cardFavriteIcon: {
        position: 'absolute',
        right: '0px'
    },
    cardTitle: {
        //color: '#bf9410',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem',
            fontWeight: '400'
        },
    },
    cardSubTitle: {
        color: 'black',
        fontSize: '1.2rem',
        fontWeight: '400',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
            fontWeight: '400'
        },
    },
    overlay: {
        position: 'absolute',
        left: '50%',
        bottom: '10px',
        transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent',
        userSelect: 'none',
    },
    status: {
        //border: 'solid 1px black',
        backgroundColor: 'transparent',
        color: 'white',
        height: '30px',
        font: 'normal normal normal 12px Helvetica, Arial, sans-serif',
        //padding: '10px',
        '-webkit-transform-origin': 'center center',
        '-webkit-transform': 'rotate(315deg)',
        '-moz-transform-origin': 'center center',
        '-moz-transform': 'rotate(315deg)',
        '-ms-transform-origin': 'center center',
        '-ms-transform': 'rotate(315deg)',
        '-o-transform-origin': 'center center',
        '-o-transform': 'rotate(315deg)',
        'transform-origin': 'center center',
        'transform': 'rotate(315deg)',
        position: 'absolute',
        top: '30px',
        left: '0px',
        userSelect: 'none',
    }
}))

function TourCard({ floorplan, project }) {
    const classes = useStyles()
    return (
        <Card className={classes.cardRoot}>
                <Link to={'/section/' + project + '/' + floorplan.tour}>
                <CardMedia>
                    <img className="w-100 h-100" src={floorplan.image} alt={floorplan.heading} style={{display: "block"}} />
                </CardMedia>
                <div className={classes.overlay}>
                    <Typography variant="body2" className={classes.cardSubTitle}>
                        {floorplan.heading}
                    </Typography>
                </div>
                </Link>
        </Card>
    )
}

export default TourCard