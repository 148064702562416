import { Box, Grid, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import TourCard from '../widgets/TourCard'
import { ApiContext } from '../../context/ApiContext'
import Navbar from '../widgets/Navbar'
import InfiniteScroll from 'react-infinite-scroll-component'
import Heading from '../widgets/Heading'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative',
        //[theme.breakpoints.down('xs')]: {
        //height: '100vh',
        //minHeight: '0',
        //}
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            top: '30px',
            bottom: '30px'
        }
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    container: {
        marginTop: '150px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '110px',
        }
    },
    projectsSubTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    cardRoot: {
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        width: '25%',
        height: '25%',
    },
}))


function ProjectTours() {
    const classes = useStyles()
    const { id } = useParams()
    const { projectTours, loadProjectTours } = useContext(ApiContext)
    const { headingColor } = useContext(ApiContext)

    useEffect(() => {
        loadProjectTours(id)
        if (projectTours != null) {
            document.title = projectTours.title
        } else {
            document.title = "Tour"
        }
        //return () => document.title = 'Portfolio'
    }, [id])

    useEffect(() => {
        if (projectTours != null) {
            document.title = projectTours.title
        }
    }, [projectTours])
    return (
        <>
            {projectTours ?
                <div>
                    <Container maxWidth={false} className={classes.container} disableGutters>
                        <Heading title={`${projectTours.title} Tour`}/>
                        {projectTours ? <InfiniteScroll
                            dataLength={projectTours.floorplans.length}
                            next={null}
                            hasMore={false}
                            scrollThreshold={0.9}
                            style={{ overflow: 'none' }}
                        >
                            <Grid container spacing={0}>
                                {projectTours.floorplans.map((result) => (
                                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                                        <TourCard floorplan={result} project={projectTours.id} />
                                    </Grid>
                                ))}
                            </Grid>
                        </InfiniteScroll> : <></>}
                    </Container>
                </div>
                : <></>}
        </>
    )
}

export default ProjectTours
