import React from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    media: {
        // Change the image url to change the background image
        //backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(/uploads/images/map_gallery_img_1_CqBW43M.jpg)',
        backgroundSize: 'cover',
        height: '40vh',
        width: '40vw',
        //minHeight: '720px',
        //color: 'white',
        overflow: 'hidden',
        //maxWidth: '100vw',
        //position: 'relative'
        [theme.breakpoints.down('xs')]: {
            height: '40vh',
            width: '80vw',
        }
    },
    cardRoot: {
        //boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: 0,
        borderWidth: 0,
        height: '40vh',
        width: '40vw',
        [theme.breakpoints.down('xs')]: {
            height: '40vh',
            width: '80vw',
        }
    },
}))

function ImageCarousel({ items }) {
    const classes = useStyles()
    console.log(items)
    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 1,
        dotsClass: "slick-dots slick-thumb",
        customPaging: function (i) {
            return (
            <>
                {false && <div style={{ width: '8vw', borderColor: '#000000', padding: '2px' }}>
                    <img src={items[i].image} style={{height: '8vh', width: '8vw'}} />
                </div>
                }
                <div>
                        {items[i].heading}
                </div>
            </>
            );
        }
    };
    return (
        <Slider {...settings}>
            {items.map((item) => (
                <div key={item.heading}>
                    <Card className={classes.cardRoot}>
                        <CardMedia>
                            <img className={classes.media} src={item.image} alt={item.heading} style={{ display: "block" }} />
                        </CardMedia>
                    </Card>
                </div>
            ))}
        </Slider>
    )
}

export default ImageCarousel
