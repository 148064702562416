import { Box, Button, Container, Grid, Typography, Row, Col } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useState } from 'react'
import GridCard from '../widgets/GridCard'
import Heading from '../widgets/Heading'
import { ApiContext } from '../../context/ApiContext'
import InfiniteScroll from 'react-infinite-scroll-component'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '150px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '110px',
        }
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    cardRoot: {
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        width: '25%',
        height: '25%',
    },
    selected: {
        fontSize: '1.2rem',
        fontWeight: '600',
        //fontStyle: 'italic',
    },
    unselected: {
        fontSize: '1.2rem',
        fontWeight: '400',
        //fontStyle: 'italic',
    }
}))
function LazyGrid() {
    const { projects, loadMoreProjects, category } = useContext(ApiContext)
    const classes = useStyles()
    const [filterResult, setFilterResult] = useState(null)
    const setFilter = (filter) => {
        setFilterResult(filter)
    }
    console.log(category)
    return (
        <div>
            <Container maxWidth={false} className={classes.container} disableGutters>
                <Heading title={`${category.heading} Collection`}
                    body={(
                        <div style={{ paddingLeft: 10, color: 'white' }}>
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={0}>
                                    <Typography className={filterResult == null ? classes.selected : classes.unselected} onClick={(filter) => setFilter(null)}>All</Typography>
                                </Grid>
                                <Grid item xs={0}>
                                    <Typography className={classes.unselected}>/</Typography>
                                </Grid>
                                <Grid item xs={0}>
                                    <Typography className={filterResult == "following" ? classes.selected : classes.unselected} onClick={(filter) => setFilter("following")}>Following</Typography>
                                </Grid>
                                {false ? projects.results.filter((result) => {
                                    return result.following || !filterResult
                                }).map((result) => (
                                    <Grid item xs={0}>
                                        <Typography className={filterResult == { result } ? classes.selected : classes.unselected} onClick={(filter) => setFilter(result)}>{result}</Typography>
                                    </Grid>
                                )) : <></>}
                            </Grid>
                        </div>)}
                />
                {projects ? <InfiniteScroll
                    dataLength={projects.results.length}
                    next={loadMoreProjects}
                    hasMore={true}
                    scrollThreshold={0.9}
                    style={{ overflow: 'none' }}
                >
                    <Grid container spacing={0}>
                        {projects.results.filter((result) => {
                            return result.following || !filterResult
                        }).map((result) => (
                            <Grid item xl={3} lg={3} md={3} sm={6} xs={6} key={result.id}>
                                <GridCard project={result} />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll> : <></>}
            </Container>
        </div>
    )
}

export default LazyGrid