import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect } from 'react'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import { ApiContext } from '../../context/ApiContext'
import Navbar from '../widgets/Navbar'
import { useParams, useLocation } from "react-router-dom";
import LazyGrid from './LazyGrid'


const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative',
        //[theme.breakpoints.down('xs')]: {
            //height: '100vh',
            //minHeight: '0',
        //}
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            top: '30px',
            bottom: '30px'
        }
    }
}))


function ProjectCards() {
    const { projects, loadProjects, loadMoreProjects, category, loadCategory, projectSlidesToShow } = useContext(ApiContext)
    const classes = useStyles()
    const { id } = useParams()
    const location = useLocation()
    const mode = new URLSearchParams(location.search).get('mode')
    console.log(mode)
    console.log(id)
    const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 2,
        afterChange: (index) => {
            if (index + 3 >= projects.results.length) {
                loadMoreProjects()
            }
        },
        onEdge: (event) => {
            console.log(event)
            if (event === 'left') {
                //loadMoreProjects()
            }
        },
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    afterChange: (index) => {
                        if (index + 2 >= projects.results.length) {
                            loadMoreProjects()
                        }
                    },
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    afterChange: (index) => {
                        if (index + 1 >= projects.results.length) {
                            loadMoreProjects()
                        }
                    },
                }
            },
        ]
    };

    useEffect(() => {
        loadCategory(id)
        loadProjects(id)
        if (category != null) {
            document.title = category.heading
        } else {
            document.title = "Portfolio"
        }
        return () => document.title = 'Portfolio'
    }, [id])
    return (
        <>
            <Navbar />
            {mode === 'grid' ? <LazyGrid /> : 
            <div className={classes.projectCardRoot}>
                <Box pl={3} pr={3} className={classes.cardCarouselContainer}>
                    {category != null ? 
                        <h1 className={classes.projectsTitle}><span className={classes.colorText}>{category.heading}</span></h1>
                        : <></>}
                    <div>
                        {projects != null ?
                            <>
                                <Grid container justify='center'>
                                    <Grid item xl={projectSlidesToShow > 2 ? 10 : 6} lg={projectSlidesToShow > 2 ? 10 : 6} md={projectSlidesToShow > 2 ? 10 : 6} sm={11} xs={11}>
                                        <Slider {...settings} slidesToShow={projectSlidesToShow}>
                                            {projects.results.map((d) => (
                                                <ProjectCard image={d.thumbnail} title={d.title} subTitle={d.description} id={d.id} key={d.id} />
                                            ))}
                                        </Slider>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>}
                        </div>
                    </Box>
                </div>
            }
        </>
    )
}

export default ProjectCards
