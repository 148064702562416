import React, { useReducer, useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {ApiContext} from '../../context/ApiContext'
import { CircularProgress, Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Container, Grid } from '@material-ui/core'
import Navbar from '../widgets/Navbar'
import Heroarea from '../widgets/Heroarea'
import Heading from '../widgets/Heading'
import Large from '../widgets/Large'
import Mini from '../widgets/Mini'
import Carousel from '../widgets/sub_widgets/Carousel'
import Projects from '../widgets/Projects'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import { Form, Button } from 'react-bootstrap'
import { SocialIcon } from 'react-social-icons'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(/uploads/images/map_gallery_img_1_CqBW43M.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '720px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative'
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '50px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            bottom: '25px'
        }
    },
    container: {
        marginTop: '100px',
        //[theme.breakpoints.down('sm')]: {
        //    marginTop: '90px',
        //},
        //[theme.breakpoints.down('xs')]: {
        //    marginTop: '90px',
        //}
    },
    heading: {
        fontSize: '2.6rem',
        fontWeight: '500',
        //textAlign: 'center',
        margin: '0px',
        lineHeight: '1em',
        marginTop: '25px',
        marginBottom: '25px',
        marginLeft: '20px',
        letterSpacing: '0.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.0rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8rem'
        }
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '500',
        //letterSpacing: '0.2em',
        fontSize: '1.375rem',
        //'& .sub-title': {
        //    textAlign: 'center'
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem'
        }
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '400',
        //letterSpacing: '0.2em',
        fontSize: '1.1rem',
        '& .sub-title': {
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    gridItem: {
        paddingHorizontal: '20px',
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
}))

const formReducer = (state, event) => {
    if (event.reset) {
        return {
            email: '',
            phone: '',
            name: '',
            message: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

function ContactUs() {
    //const { siteData, dataLoaded, loadProject } = useContext(ApiContext)
    //const { id } = useParams()
    /*const loadData = () => {
        loadProject(id).then((data) => {
            console.log(data)
        }).catch(error => {
            console.log(error)
        })
        console.log('dma man')
    }*/
    const classes = useStyles()
    /*const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 3,
        centerMode: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    useEffect(() => {
        loadData()
    }, [])
    if(!dataLoaded){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )
    }*/
    const [formData, setFormData] = useReducer(formReducer, {
    });
    const [submitting, setSubmitting] = useState(false);

    const { setApiKey } = useContext(ApiContext);
    const { contactFormSubmit, postContactFormSubmit } = useContext(ApiContext);

    const handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);
        postContactFormSubmit(formData)
        //setApiKey(formData);
        setSubmitting(false);

        //setTimeout(() => {
        //    setSubmitting(false);
        //}, 3000);
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        setFormData({
            name: event.target.name,
            value: isCheckbox ? event.target.checked : event.target.value,
        })
    }
    useEffect(() => {
        if (contactFormSubmit) {
            console.log(contactFormSubmit)
        }
    }, [contactFormSubmit])
    return (
        <>
                <Container maxWidth={false} className={classes.container} disableGutters>
                    <Heading title="Contact Us" />
                    <div style={{ padding: 20 }}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} xm={6} xl={6} lg={6} md={6} className={classes.gridItem}>
                            <Box px={3}>
                            <Typography className={classes.title}>JG Land Group of Companies</Typography>
                            <Typography className={classes.body}>33 Ubi Avenue 3, #06-50</Typography>
                            <Typography className={classes.body}>Vertex Tower A</Typography>
                            <Typography className={classes.body}>Singapore 408868</Typography>
                            <br />
                            <Typography className={classes.body}>Tel: 65096868</Typography>
                                <Typography className={classes.body}>Email: <a href="mailto:enquiry@jgland.com.sg">enquiry@jgland.com.sg</a></Typography>
                                <Typography className={classes.body}>Social Media: <SocialIcon url="https://www.instagram.com/jgland.collections/" /></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6} lg={6} md={6} className={classes.gridItem}>
                            <Box px={3}>
                            <p><Typography className={classes.body}>Leave us a message and we will get back to you shortly. We look forward to hearing from you.</Typography></p>
                            {contactFormSubmit &&
                                <div>
                                <p className="text-muted">We {Number.isInteger(contactFormSubmit) ? 'received' : 'failed to received'} your message.</p>
                                {false && <ul>
                                    {Object.entries(formData).map(([name, value]) => (
                                        <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                                    ))}
                                </ul>
                                }
                                </div>
                            }
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Name" name="name" onChange={handleChange} value={formData.name || ''} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange} value={formData.email || ''} />
                                </Form.Group>
                                <Form.Group controlId="formBasicNumber">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="tel" placeholder="Enter number" name="number" onChange={handleChange} value={formData.number || ''} />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={handleChange} name="message" value={formData.message || ''} />
                                </Form.Group>
                                {false && <Form.Text className="text-muted">
                                    We will never share your phone or number with anyone else.
                                </Form.Text>}
                                <Button variant="primary" type="submit" disabled={submitting}>
                                    Submit
                                </Button>
                            </Form>
                            </Box>
                        </Grid>
                        </Grid>
                    </div>
                </Container>
        </>
    )
}

export default ContactUs
