import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {ApiContext} from '../../context/ApiContext'
import {CircularProgress } from '@material-ui/core'
import Navbar from '../widgets/Navbar'
import StaticHeroarea from '../widgets/StaticHeroarea'
import Large from '../widgets/Large'
import Mini from '../widgets/Mini'
import Carousel from '../widgets/sub_widgets/Carousel'
import OurGallery from '../widgets/OurGallery'
import Projects from '../widgets/Projects'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '50vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative'
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '50px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            bottom: '25px'
        }
    }
}))

function StaticContent() {
    const { staticData, loadStaticData } = useContext(ApiContext)
    const { id } = useParams()
    const classes = useStyles()
    const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 3,
        centerMode: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    useEffect(() => {
        if (id != null) {
            loadStaticData(id)
        } else {
            loadStaticData("home")
        }
    }, [id])
    //useState(() => {
    //    loadStaticData(id)
    //}, [])
    /*if(!dataLoaded){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )
            <h1>{staticData.content}</h1>
    }*/
    return (
        <div>
            <Navbar />
            {staticData != null ? (
                <StaticHeroarea heading={staticData.heading} subHeading={staticData.subheading} image={staticData.cover} />
            ) : <></>}
        </div>
    )
}

export default StaticContent
