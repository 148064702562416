import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Typography, Box, Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MyTabs, { MyTab } from '../custom_mui_components/MyTabs'
import TabPanel from '../custom_mui_components/TabPanel'
import { ExpandMore } from '@material-ui/icons'
import OurGallery from '../widgets/OurGallery'
import Mini from '../widgets/Mini'
import Carousel from '../widgets/sub_widgets/Carousel'
//import TourGallery from '../widgets/TourGallery'
//import { Tabs, Tab } from 'react-bootstrap'
import { Tabs, Tab } from '@material-ui/core'
import { ApiContext } from '../../context/ApiContext'
import { Suspense } from "react"
import { Canvas, useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Html, OrbitControls } from "@react-three/drei"
import { Modal } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    projectRoot: {
        paddingTop: '40px',
        paddingBottom: '20px'
    },
    tabPanel: {
        paddingTop: '50px',
    },
    mediaImage: {
        width: '100%'
    },
    sectionTitle: {
        paddingLeft: '10px',
        fontSize: '2.4rem',
        margin: 0,
        marginBottom: '10px',
        fontWeight: '400'
    },
    sectionSubTitle: {
        paddingLeft: '10px',
        fontSize: '1.2rem',
        letterSpacing: '1px',
        margin: 0,
        marginBottom: '10px',
        fontStyle: 'italic',
        fontWeight: '500'
    },
    sectionText: {
        paddingLeft: '10px',
        fontSize: '1rem',
        opacity: '0.7',
        lineHeight: '150%'
    },
    tabLinkContainer: {
        width: '90vw',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        margin: '0',
        padding: '0',
    },
    tabs: {
        orientation: 'horizontal',
        [theme.breakpoints.down('xs')]: {
            orientation: 'vertical',
        }
    }
}))

function Large({ larges, floorplans, mini, carousel, title, description, writeup, logo, project, model}) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(1)
    const [activeIndex, setActiveIndex] = useState(0)
    const { apiToken } = useContext(ApiContext)
    const handleAccordion = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
      };
    const handleChange = (_, newIndex) => {
        console.log(activeIndex)
        console.log(newIndex)
        setActiveIndex(newIndex)
    }
    const [modalShow, setModalShow] = useState(null);
    //const [model, setModel] = useState(null);

    function Keen(props) {
        const { scene } = useLoader(GLTFLoader, model)
        return <primitive object={scene} dispose={null} {...props} />
    }

    //useEffect(() => {
    //    setModel(useLoader(GLTFLoader, modalShow))
    //}, [modalShow])

    //<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}></div>

    return (
        <Container maxWidth="lg" className={classes.projectRoot}>

            <div>
                <Tabs value={activeIndex} onChange={handleChange} centered={true} className={classes.tabs} variant="scrollable" scrollButtons="on">
                    <Tab disableRipple={true} label="About" key={0} />
                    {larges.map(project => (
                        <Tab disableRipple={true} label={project.title} key={project.id} />
                    ))}
                    <Tab disableRipple={true} label="Tour" key={larges.length + 1} />
                    <Tab disableRipple={true} label="Brochure" key={larges.length + 2} />
                </Tabs>
                <TabPanel value={activeIndex} index={0} key={0}>
                    <Box py={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12}>
                                {carousel.length > 0 ?
                                    <Carousel items={carousel} />
                                    : <></>}
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box py={2}>
                                    <h2 className={classes.sectionTitle}>{title}</h2>
                                    {false ? (<p className={classes.sectionSubTitle}>{description}</p>) : ""}
                                    <p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: writeup }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
                {larges.map((large) => (
                    <TabPanel value={activeIndex} index={large.id} key={large.id}>
                        <Box py={2}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <img className={classes.mediaImage} src={large.image} alt="house" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box py={2}>
                                        {large.heading ? (<h2 className={classes.sectionTitle}>{large.heading}</h2>) : ""}
                                        {large.subheading ? (<p className={classes.sectionSubTitle}>{large.subheading}</p>) : ""}
                                        {large.text ? (<p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: large.text }} />) : ""}
                                        {large.subsections.length > 0 ? (
                                            <div>
                                                {large.subsections.map(subS => (
                                                    <Accordion expanded={expanded === subS.id} key={subS.id}
                                                        style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }} // backgroundColor: 'rgba(255,255,255,0.4)', 
                                                        onChange={handleAccordion(subS.id)}>
                                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                                            <Typography variant='button' style={{ fontWeight: '600' }}>{subS.title}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: subS.text }} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        ) : ""}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                ))}
                <TabPanel value={activeIndex} index={larges.length + 1} key={larges.length + 1}>
                    <Box py={2}>
                        <Box my={0} mx={3}>
                            <Button color='primary' variant='contained' onClick={() => setModalShow(true)}>View 3D Model</Button>
                        </Box>
                        <Modal
                            show={modalShow != null}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={() => setModalShow(null)}
                            dialogClassName="project-model-dialog"
                            contentClassName="project-model-content"
                        >
                            <div style={{ height: '90vh', width: '90vw', margin: 0, padding: 0 }}>
                                <Canvas invalidateFrameloop camera={{ position: [-40, 0, 15], far: 50 }}>
                                    <ambientLight />
                                    <spotLight intensity={2} position={[20, 20, 20]} />
                                    <Suspense fallback={<Html center>loading..</Html>}>
                                        <Keen position={[0, -5, 0]} />
                                    </Suspense>
                                    <OrbitControls />
                                </Canvas>
                            </div>
                        </Modal>
                        {
                            floorplans.length > 0 ?
                                <OurGallery floorplans={floorplans} project={project} />
                                : <></>
                        }
                    </Box>
                </TabPanel>
                <TabPanel value={activeIndex} index={larges.length + 2} key={larges.length + 2}>
                    <Box py={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12}>
                                <img className={classes.mediaImage} src={logo} alt="house" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box py={2}>
                                    <h2 className={classes.sectionTitle}>Download Prospectus</h2>
                                    <p className={classes.sectionText}>{apiToken != null ? "Follow this project to download" : "You must be logged in to download"}</p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
                {false && <Tabs fill justify defaultActiveKey={activeIndex} onSelect={(k) => setActiveIndex(k)} className={classes.tabLinkContainer}>
                    <Tab eventKey={0} title="About">
                        <Box py={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12}>
                                {carousel.length > 0 ?
                                    <Carousel items={carousel} />
                                    : <></>}
                            </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box py={2}>
                                        <h2 className={classes.sectionTitle}>{title}</h2>
                                        {false ? (<p className={classes.sectionSubTitle}>{description}</p>) : ""}
                                        <p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: writeup }} />
                                    </Box>
                            </Grid>
                            </Grid>
                        </Box>
                    </Tab>
                    {larges.map((project) => (
                        <Tab eventKey={project.id} title={project.title}>
                            <Box py={2}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <img className={classes.mediaImage} src={project.image} alt="house" />
                                </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box py={2}>
                                    {project.heading ? (<h2 className={classes.sectionTitle}>{project.heading}</h2>) : ""}
                                    {project.subheading ? (<p className={classes.sectionSubTitle}>{project.subheading}</p>) : ""}
                                    {project.text ? (<p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: project.text }} />) : ""}
                                    {project.subsections.length > 0 ? (
                                        <div>
                                            {project.subsections.map(subS => (
                                                <Accordion expanded={expanded === subS.id} key={subS.id}
                                                    style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }} // backgroundColor: 'rgba(255,255,255,0.4)', 
                                                    onChange={handleAccordion(subS.id)}>
                                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                                        <Typography variant='button' style={{ fontWeight: '600' }}>{subS.title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: subS.text }} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </div>
                                            ) : ""}
                                        </Box>
                                </Grid>
                                </Grid>
                            </Box>
                        </Tab>
                    ))}
                    <Tab eventKey={larges.length + 1} title="Tour">
                        <Box py={2}>
                        {
                            floorplans.length > 0 ?
                                    <OurGallery floorplans={floorplans} project={project} />
                                : <></>
                        }
                        </Box>
                    </Tab>
                    <Tab eventKey={larges.length + 2} title="Brochure">
                        <Box py={2}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <img className={classes.mediaImage} src={logo} alt="house" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box py={2}>
                                        <h2 className={classes.sectionTitle}>Download Prospectus</h2>
                                        <p className={classes.sectionText}>{apiToken != null ? "Follow this project to download" : "You must be logged in to download"}</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Tab>
                    {false && <Tab eventKey={larges.length + 2} title="At a Glance">
                        <Box py={2}>
                            {mini.length > 0 ?
                                <Mini minis={mini} />
                                : <></>}
                        </Box>
                    </Tab>}
                </Tabs>}
            </div>

            {false && (
                <>
                <Grid container>
                    <Grid item container justify="center" lg={12} sm={12} md={12} xs={12} xl={12}>
                        <MyTabs value={activeIndex} onChange={handleChange} centered={true}
                            variant="scrollable"
                            scrollButtons="on">
                            <MyTab disableRipple={true} label="About" key={0} />
                            {larges.map(project => (
                                <MyTab disableRipple={true} label={project.title} key={project.id} />
                            ))}
                            <MyTab disableRipple={true} label="Visualise" key={larges.length + 1} />
                            <MyTab disableRipple={true} label="At a Glance" key={larges.length + 2} />
                        </MyTabs>
                    </Grid>
                </Grid>
                <div>
                    <TabPanel value={activeIndex} index={0} className={classes.tabPanel}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12}>
                                {carousel.length > 0 ?
                                    <Carousel items={carousel} />
                                    : <></>}
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <h2 className={classes.sectionTitle}>{title}</h2>
                                {false ? (<p className={classes.sectionSubTitle}>{description}</p>) : ""}
                                <p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: writeup }} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {larges.map((project) => (
                        <TabPanel value={activeIndex} index={project.id} className={classes.tabPanel}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <img className={classes.mediaImage} src={project.image} alt="house" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {project.id}
                                    {project.heading ? (<h2 className={classes.sectionTitle}>{project.heading}</h2>) : ""}
                                    {project.subheading ? (<p className={classes.sectionSubTitle}>{project.subheading}</p>) : ""}
                                    {project.text ? (<p className={classes.sectionText} dangerouslySetInnerHTML={{ __html: project.text }} />) : ""}
                                    {project.subsections.length > 0 ? (
                                        <div>
                                            {project.subsections.map(subS => (
                                                <Accordion expanded={expanded === subS.id} key={subS.id}
                                                    style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }} // backgroundColor: 'rgba(255,255,255,0.4)', 
                                                    onChange={handleAccordion(subS.id)}>
                                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                                        <Typography variant='button' style={{ fontWeight: '600' }}>{subS.title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: subS.text }} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </div>
                                    ) : ""}
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))}
                    <TabPanel value={activeIndex} index={larges.length + 1} className={classes.tabPanel}>
                        {
                            floorplans.length > 0 ?
                                <OurGallery floorplans={floorplans} />
                                : <></>
                        }
                    </TabPanel>
                    <TabPanel value={activeIndex} index={larges.length + 2} className={classes.tabPanel}>
                        {mini.length > 0 ?
                            <Mini minis={mini} />
                            : <></>}
                    </TabPanel>
                    </div>
                    </>
                )}
        </Container>
    )
}

export default Large
