import React, { createContext, useState } from 'react'
import axios from 'axios'

export const ApiContext = createContext()

function ApiProvider(props) {
    const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') || null)
    const [userData, setUserData] = useState(null)
    const [contactFormSubmit, setContactFormSubmit] = useState(null)
    const [siteData, setSiteData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [projects, setProjects] = useState()
    const [categories, setCategories] = useState()
    const [projectSlidesToShow, setProjectSlidesToShow] = useState(3)
    const [categorySlidesToShow, setCategorySlidesToShow] = useState(3)
    const [bannerData, setBannerData] = useState()
    const [staticData, setStaticData] = useState()
    const [staticLinks, setStaticLinks] = useState()
    const [category, setCategory] = useState()
    const [panoramaImage, setPanoramaImage] = useState()
    const [showcaseProjects, setShowcaseProjects] = useState()
    const [pageBackground, setPageBackground] = useState(null)
    const [logoDark, setLogoDark] = useState(false)
    const [headingDark, setHeadingDark] = useState(false)
    const [lockHeight, setLockHeight] = useState(false)
    const [projectTours, setProjectTours] = useState()
    const [submitting, setSubmitting] = useState(null)
    const [formError, setFormError] = useState(null)
    //const [profile, setProfile] = useState(null)

    const loadStaticLinks = () => {
        axios.get('/api/nav/').then((response) => {
            setStaticLinks(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const loadApiToken = () => {
        axios.get('/api/user/', { headers: apiToken != null ? { Authorization: 'Token ' + apiToken } : null }).then((response) => {
            setUserData(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const saveApiToken = (newToken) => {
        if (newToken) {
            localStorage.setItem('apiToken', newToken)
        } else {
            localStorage.removeItem('apiToken')
        }
        setApiToken(newToken)
    }

    const loadUserData = () => {
        axios.get('/api/accounts/user/', { headers: apiToken != null ? { Authorization: 'Token ' + apiToken } : null }).then((response) => {
            setUserData(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const loadStaticData = (staticId) => {
        axios.get(`/api/content/${staticId}/`).then((response) => {
            setStaticData(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const loadBannerData = (staticId) => {
        axios.get(`/api/links/${staticId}/`).then((response) => {
            setBannerData(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const loadProject = (projectId) => {
        setSiteData(null)
        axios.get(`/api/projects/${projectId}/`).then((response) => {
            setSiteData(response.data)
        }).catch(error => {
            console.log(error)
        })
        // #TODO: chnage the route to your project route
        /*setDataLoaded(false)
        return new Promise((resolve, reject) => {
            if (!dataLoaded) {
                axios.get(`/api/projects/${projectId}/`).then((response) => {
                    setSiteData(response.data)
                    setDataLoaded(true)
                    resolve(response.data)
                }).catch(error => {
                    console.log(error)
                    setDataLoaded(false)
                    reject(error)
                })
            }
        })*/
    }

    const loadCategory = (categoryId) => {
        // #TODO: chnage the route to your project route
        axios.get(`/api/categories/${categoryId}/`).then((response) => {
            setCategory(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const loadShowcaseProjects = () => {
        axios.get(`/api/showcase/`).then((response) => {
            setShowcaseProjects(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const loadProjects = (categoryId) => {
        axios.get(`/api/projects/?category=${categoryId}`).then((response) => {
            setProjectSlidesToShow(response.data.results.length > 3 ? 3 : Math.max(1, response.data.results.length - 1))
            setProjects(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const loadMoreProjects = () => {
        if (projects.next !== null) {
            axios.get(projects.next).then((response) => {
                const newPorject = {
                    ...response.data,
                    results: [
                        ...projects.results,
                        ...response.data.results
                    ]
                }
                setProjects(newPorject)
            }).catch(error => {
                console.log(error)
            })
        } else {
            console.log('reached at the end')
        }
    }

    const loadCategories = () => {
        axios.get('/api/categories/').then((response) => {
            setCategorySlidesToShow(response.data.results.length > 4 ? 4 : response.data.results.length) //Math.max(1, response.data.results.length - 1)
            setCategories(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const loadMoreCategories = () => {
        if (categories.next) {
            axios.get(categories.next).then((response) => {
                const newCategories = {
                    ...response.data,
                    results: [
                        ...categories.results,
                        ...response.data.results
                    ]
                }
                setCategories(newCategories)
            }).catch(error => {
                console.log(error)
            })
        } else {
            console.log('reached at the end')
        }
    }

    //const loadProjectTours = (projectId) => {
    //    setProjectTours(null)
    //    axios.get(`/api/tour/${projectId}/`).then((response) => {
    //        setProjectTours(response.data)
    //    }).catch(error => {
    //        console.log(error)
    //    })
    //}

    const loadPanoramaImage = (project, id, scene, forDebug) => {
        console.log(scene)
        setProjectTours(null)
        axios.get(`/api/tour/${project}/`).then((response) => {
            setProjectTours(response.data)
            //if (panoramaImage) return
            axios.get(`/api/tours/${project}/?section=${id}` + (scene ? '&scene=' + scene : '') + (forDebug ? '&debug=' + forDebug : ''), { responseType: 'text' }).then((response) => {
                //console.log(response.data)
                //console.log(JSON.stringify(response.data))
                //setPanoramaImage(JSON.parse(response.data).map(obj => ({ ...obj, customLink: obj.customLink.replace("\"customLink\"", "customLink"), customLinkClick: obj.customLinkClick.replace("\"customLinkClick\"", "customLinkClick") })))
                //console.log(JSON.stringify(response.data).replace("\"customLink\"", "customLink").replace("\"customLinkClick\"", "customLinkClick"))
                //setPanoramaImage(JSON.stringify(response.data).replace("\"customLink\"", "customLink").replace("\"customLinkClick\"", "customLinkClick"))
                setPanoramaImage(response.data)
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })
    }

    const toggleProjectInterest = (projectId) => {
        axios.put(
            `/api/projects/${projectId}/interest/`,
            { 'projectId': projectId }).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    const newProjects = {
                        ...projects,
                        results: projects.results.map((res) => {
                            if (res.id === projectId) {
                                return {
                                    ...res,
                                    interested: !res.interested,
                                }
                            }
                            return res
                        })
                    }
                    setProjects(newProjects)
                } else {
                    console.log('Project interest error')
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const postLoginFormSubmit = (form) => {
        setFormError(null)
        // #TODO: chnage the route to your project route
        axios.post(`/api/accounts/login/`, form).then((response) => {
            //setContactFormSubmit(response.status)
            setSubmitting(response.status)
            console.log(response.data)
            if (response.data.key) {
                saveApiToken(response.data.key)
            }
        }).catch(error => {
            if (error.response) {
                //setContactFormSubmit(error.response.status)
                setSubmitting(error.response.status)
                setFormError(error.response.data)
            }
            //setContactFormSubmit(error)
            console.log(error)
            setSubmitting(-2)
        })
    }

    const postRegisterFormSubmit = (form) => {
        setFormError(null)
        // #TODO: chnage the route to your project route
        axios.post(`/api/accounts/signup/`, form).then((response) => {
            //setContactFormSubmit(response.status)
            setSubmitting(response.status)
            console.log(response.data)
            if (response.data.key) {
                saveApiToken(response.data.key)
            }
        }).catch(error => {
            if (error.response) {
                //setContactFormSubmit(error.response.status)
                setSubmitting(error.response.status)
                setFormError(error.response.data)
            }
            //setContactFormSubmit(error)
            console.log(error)
            setSubmitting(-2)
        })
    }

    const postLogoutFormSubmit = (form) => {
        setFormError(null)
        // #TODO: chnage the route to your project route
        axios.post(`/api/accounts/logout/`, {}, { headers: apiToken != null ? { Authorization: 'Token ' + apiToken } : null }).then((response) => {
            //setContactFormSubmit(response.status)
            setSubmitting(response.status)
            console.log(response.data)
            if (response.status == 200) {
                saveApiToken(null)
            }
        }).catch(error => {
            if (error.response) {
                //setContactFormSubmit(error.response.status)
                setSubmitting(error.response.status)
                setFormError(error.response.data)
            }
            //setContactFormSubmit(error)
            console.log(error)
            setSubmitting(-2)
        })
    }

    const postUpdateFormSubmit = (form) => {
        setFormError(null)
        // #TODO: chnage the route to your project route
        axios.put(`/api/accounts/user/`, form, { headers: apiToken != null ? { Authorization: 'Token ' + apiToken } : null }).then((response) => {
            //setContactFormSubmit(response.status)
            setSubmitting(response.status)
            console.log(response.data)
            if (response.status == 200) {
                setUserData(response.data)
            }
        }).catch(error => {
            if (error.response) {
                //setContactFormSubmit(error.response.status)
                setSubmitting(error.response.status)
                setFormError(error.response.data)
            }
            //setContactFormSubmit(error)
            console.log(error)
            setSubmitting(-2)
        })
    }
    
    const postContactFormSubmit = (form) => {
        // #TODO: chnage the route to your project route
        axios.post(`/api/contactform/`,form).then((response) => {
            setContactFormSubmit(response.status)
        }).catch(error => {
            if (error.response) {
                setContactFormSubmit(error.response.status)
            }
            //setContactFormSubmit(error)
            console.log(error)
        })
    }

    return (
        <ApiContext.Provider value={{
            siteData, dataLoaded, loadProject, projects, loadProjects, loadMoreProjects, categories, loadCategories, loadMoreCategories, staticData, loadStaticData, staticLinks, loadStaticLinks, category, loadCategory, projectSlidesToShow, categorySlidesToShow, loadPanoramaImage, panoramaImage, setPanoramaImage, toggleProjectInterest, apiToken, loadApiToken, contactFormSubmit, setContactFormSubmit, postContactFormSubmit, bannerData, loadBannerData, showcaseProjects, loadShowcaseProjects, userData, loadUserData, pageBackground, setPageBackground, projectTours, logoDark, setLogoDark, headingDark, setHeadingDark, lockHeight, setLockHeight, postRegisterFormSubmit, postLoginFormSubmit, submitting, setSubmitting, formError, setFormError, postLogoutFormSubmit, postUpdateFormSubmit
        }}>
            {{ ...props.children }}
        </ApiContext.Provider>
    )
}

export default ApiProvider
