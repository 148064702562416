import React, { useState, useContext, useEffect } from 'react'
import { ApiContext } from '../../context/ApiContext'
import {makeStyles} from '@material-ui/core/styles'
import { Container, Grid} from '@material-ui/core'
import Project from '../custom_mui_components/Project'
const useStyles = makeStyles((theme)=>({
    textRoot: {
        textAlign: 'center'
    },
    title: {
        color: '#bf9410'
    },
    heading: {
        fontSize: '4rem',
        margin: 0,
        marginBottom: '40px',
        fontWeight: '400'
    },
    largeAvater: {
        height: theme.spacing(7),
        width: theme.spacing(7)
    }
}))
function Projects({featured}) {
    /*const { loadProjects } = useContext(ApiContext)
    const [projects, setProjects] = useState()
    useEffect(() => {
        if (!projects) {
            loadProjects().then((data) => {
                setProjects(data)
            })
        }
    }, [projects, loadProjects])
    if (!projects) {
        return (<div></div>)
    }*/
    const classes = useStyles()
    return (
        <Container maxWidth="lg" className='section-padding-t'>
            <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.textRoot}>
                    <p className={classes.title}>Other Projects</p>
                    <h2 className={classes.heading}>Other Projects</h2>
                </Grid>
                {featured.map((tes) => (
                    <Grid item lg={4} md={4} sm={6} xs={12} key={tes.image}>
                        <Project avater={tes.thumbnail} title={`“${tes.title}”`} name={tes.description} profesion="" />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default Projects
