import { Box, Container, Grid, IconButton, Button } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import ImageCarousel from './sub_widgets/ImageCarousel'
import ReactImageMagnify from 'react-image-magnify';
//import MyTabs, { MyTab } from '../custom_mui_components/MyTabs'
import TabPanel from '../custom_mui_components/TabPanel'
import { ThreeDRotation } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import TourViewer from '../views/TourViewer'
import { Modal } from 'react-bootstrap'
import ReactImageZoom from 'react-image-zoom'
import { Tabs, Tab } from '@material-ui/core'
//import { TabPanel } from '@material-ui/lab'


const useStyles = makeStyles((theme)=>({
    textRoot: {
        textAlign: 'center'
    },
    title: {
        color: '#bf9410'
    },
    heading: {
        fontSize: '4rem',
        margin: 0,
        marginBottom: '40px',
        fontWeight: '400'
    },
    pt_3: {
        paddingTop: '20px'
    },
    orderTwo: {
        [theme.breakpoints.down('sm')]: {
            order: 2
        }
    },
    orderThree: {
        [theme.breakpoints.down('sm')]: {
            order: 3
        }
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardFavriteIcon: {
        //position: 'absolute',
        //right: '30px',
        //top: '30px'
    },
    tabs: {
        orientation: 'horizontal',
        [theme.breakpoints.down('xs')]: {
            orientation: 'vertical',
        }
    }
    
}))

function OurGallery({ project, floorplans }) {
    const classes = useStyles()
    const history = useHistory()
    const [activeIndex, setActiveIndex] = useState(0)
    const handleChange = (_, newIndex) => {
        setActiveIndex(newIndex)
    }
    const [modalShow, setModalShow] = useState(null);
    // console.log(floorplans)
    //<Grid item lg={12} md={12} sm={12} xs={12} className={classes.textRoot}>
    //    <h1 className={classes.projectsTitle}><span className={classes.colorText}>Gallery</span></h1>
    //</Grid>
    return (
        <Container>
            <Grid container className="section-padding-t" spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.orderThree}>
                    {floorplans.map((fp) => (
                        <TabPanel value={activeIndex} index={fp.id - 1} key={fp.id - 1}>
                            <Box my={2}>
                                <Button color='primary' variant={fp.tour ? 'contained' : 'outlined'} onClick={(e) => { history.push('/tour/' + project + '/' + fp.tour.id) }}>{fp.tour ? 'Start Virtual Tour' : 'No Virtual Tour'}</Button>
                            </Box>
                            <ImageCarousel items={fp.sections} />
                        </TabPanel>
                    ))}
                </Grid>
                <Grid item container lg={6} md={6} sm={12} xs={12} className={classes.orderTwo}>
                    <Grid item container justify="center" xs={12} sm={12} md={12} lg={12}>
                        <Tabs value={activeIndex} onChange={handleChange} centered={true} className={classes.tabs} variant="scrollable" scrollButtons="on">
                            {floorplans.map(fp => (
                                <Tab disableRipple={true} label={fp.heading} key={fp.heading} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {floorplans.map((fp) => (
                            <TabPanel value={activeIndex} index={fp.id - 1} key={fp.id - 1}>
                                <Box display="flex" justifyContent="center" className={classes.pt_3}>
                                    <img src={fp.image} alt={fp.heading} style={{ maxWidth: '300px', maxHeight: '300px' }} onClick={() => setModalShow(fp.image)} />
                                    {false && <ReactImageZoom width={400} height={400} img={fp.image} zoomPosition="original" />}
                                    {false && <Button color='primary' variant={fp.tour ? 'contained' : 'outlined'} onClick={fp.tour ? () => setModalShow(fp.tour.id) : null}>3D Tour</Button>}
                                </Box>
                            </TabPanel>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            {false && <Modal
                show={modalShow != null}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setModalShow(null)}
            >
                <Modal.Body>
                    <TourViewer id={modalShow} />
                </Modal.Body>
            </Modal>}
            <Modal
                show={modalShow != null}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setModalShow(null)}
                dialogClassName="fp-model-dialog"
                contentClassName="fp-model-content"
            >
                <div style={{ height: '90vh', width: '90vw', margin: 0, padding: 0 }}>
                    <img src={modalShow} style={{ height: '80vw', width: '80vw' }} />
                </div>
            </Modal>
        </Container>
    )
}

export default OurGallery
