import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase';
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import MyButton from '../../custom_mui_components/MyButton'
import {useHistory} from 'react-router-dom'
const useStyles = makeStyles((theme)=>({
    //cardAction: {
    //    paddingBottom: '20px',
    //    display: 'flex',
    //    justifyContent: 'center'
    //},
    cardRoot: {
        //boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: 0,
        borderWidth: 0,
        //height: '100vh',
    },
    cardMedia: {
        display: "block",
        height: 'calc(100vh - 100px)',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 100px)',
            height: 'calc(-webkit-fill-available - 100px)',
            height: 'calc(-moz-available - 100px)',
        },
    },
    cardTitle: {
        color: '#bf9410',
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    },
    overlay: {
        position: 'absolute',
        left: '50%',
        bottom: '30px',
        [theme.breakpoints.down('xs')]: {
            bottom: '30px',
        },
        transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent',
        color: '#000000',
        opacity: '0.8',
    },
    cardSubTitle: {
        color: 'white',
        fontSize: '1.2rem',
        fontWeight: '400',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
            fontWeight: '400'
        },
    },
    cardTitle: {
        color: 'white',
        fontSize: '2.4rem',
        fontWeight: '700',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.6rem',
            fontWeight: '600'
        },
    },
}))

function CategoryCardGrid({image, title, subTitle, id}) {
    const classes = useStyles()
    const history = useHistory()
    return (
        <Box paddingLeft={0} paddingRight={0}>
            <Card className={classes.cardRoot}>
                <ButtonBase
                    className={classes.cardAction}
                    onClick={(e) => { history.push('/portfolio/' + id) }}
                >
                    <CardActionArea disableRipple>
                        <CardMedia component="img"
                            alt="image"
                            image={image}
                            className={classes.cardMedia}
                        />
                        <div className={classes.overlay}>
                            <Typography className={classes.cardTitle} variant="h5" gutterBottom>
                                {title}
                            </Typography>
                        </div>
                    </CardActionArea>
                </ButtonBase>
            </Card>
        </Box>
    )
}

export default CategoryCardGrid
