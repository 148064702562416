import { Box, Button, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect } from 'react'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import { ApiContext } from '../../context/ApiContext'
import Navbar from '../widgets/Navbar'
import { useParams, useLocation } from "react-router-dom";
import LazyGrid from './LazyGrid'


const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative',
        //[theme.breakpoints.down('xs')]: {
            //height: '100vh',
            //minHeight: '0',
        //}
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            top: '30px',
            bottom: '30px'
        }
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
}))


function ProjectCardsGrid() {
    const { projects, loadProjects, loadMoreProjects, category, loadCategory, projectSlidesToShow } = useContext(ApiContext)
    const classes = useStyles()
    const { id } = useParams()
    const location = useLocation()
    const mode = new URLSearchParams(location.search).get('mode')
    console.log(mode)
    console.log(id)
    const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 2,
        afterChange: (index) => {
            if (index + 3 >= projects.results.length) {
                loadMoreProjects()
            }
        },
        onEdge: (event) => {
            console.log(event)
            if (event === 'left') {
                //loadMoreProjects()
            }
        },
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    afterChange: (index) => {
                        if (index + 2 >= projects.results.length) {
                            loadMoreProjects()
                        }
                    },
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    afterChange: (index) => {
                        if (index + 1 >= projects.results.length) {
                            loadMoreProjects()
                        }
                    },
                }
            },
        ]
    };

    useEffect(() => {
        loadCategory(id)
        loadProjects(id)
        if (category != null) {
            document.title = category.heading + " Collection"
        } else {
            document.title = "Portfolio"
        }
        //return () => document.title = 'Portfolio'
    }, [id])

    useEffect(() => {
        if (category != null) {
            document.title = category.heading
        }
    }, [category])
    return (
            <>
            {category ? <LazyGrid category={category} /> : <></>}
            </>
    )
}

export default ProjectCardsGrid
