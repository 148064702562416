import React, { useEffect, useContext, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import Navbar from '../widgets/Navbar'
import { Fab, Grid, Container, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import 'pannellum/build/pannellum'
import 'pannellum/build/pannellum.css'
import { ApiContext } from '../../context/ApiContext'
import { MusicNote, MusicOff } from '@material-ui/icons'
import { Button } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    panoramaContainer: {
        minHeight: '720px',
        height: '100vh',
        //width: '100vw',
        margin: '0',
        padding: '0',
    },
    container: {
        //marginTop: '100px',
        //margin: '0',
        //padding: '0',
        //marginTop: '130px',
        //[theme.breakpoints.down('sm')]: {
        //    marginTop: '90px',
        //},
        //[theme.breakpoints.down('xs')]: {
        //    marginTop: '90px',
        //}
    },
    shadowCorner: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, .5)',
        bottom: '10px',
        right: '30px',
        userSelect: 'none',
        width: '10vw',
    },
    overlayCorner: {
        //left: '50%',
        //top: 'calc(50% + 100px)',
        //transform: 'translate(-50%, -50%)',
        //left: '20px',
        backgroundColor: 'transparent',
        userSelect: 'none',
        //height: '50vh',
        display: 'flex',
        justifyContent: 'end',
        //paddingLeft: '200px',
        //paddingRight: '200px',
        //opacity: '1.0',
        color: 'black',
    },
    overlay: {
        position: 'absolute',
        //background: 'rgba(255, 255, 255, .5)',
        bottom: '10px',
        right: '10px',
        //userSelect: 'none',
        //width: '10vw',
    },
}))

function Photo360View() {
    const [audio, setAudio] = useState()
    const [playing, setPlaying] = useState(true)
    const { id, project } = useParams()
    const [viewer, setViewer] = useState()
    const { loadPanoramaImage, panoramaImage, setPanoramaImage, projectTours } = useContext(ApiContext)
    const location = useLocation()
    const forDebug = new URLSearchParams(location.search).get('debug')
    const consoleDebug = new URLSearchParams(location.search).get('consoledebug')
    const scene = new URLSearchParams(location.search).get('scene')
    const classes = useStyles()
    const [tourId, setTourId] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePlay = () => {
        if (playing) {
            audio.pause()
            setPlaying(false)
        } else {
            audio.loop = true
            audio.play()
            setPlaying(true)
        }
    }
    useEffect(() => {
        setPanoramaImage(null)
    }, [])

    useEffect(() => {
        setTourId(id)
    }, [id])

    useEffect(() => {
        setPanoramaImage(null)
        if (project) {
            //loadProjectTours(project)
            loadPanoramaImage(project, tourId, scene, forDebug)
        }
        /*if (!tourId && project) {
            setTourId(project?.floorplans ? project.floorplans[0]?.tour : null)
        }
        if (project && tourId) {
            loadPanoramaImage(project, tourId, scene)
        }*/
    }, [project, tourId, scene])

    useEffect(() => {
        if (panoramaImage) {
            setViewer(window.pannellum.viewer(
                'panorama',
                {
                    "hotSpotDebug": consoleDebug === 'true' ? true : false,
                    ...panoramaImage,
                }
            ))
            let soundToPlay
            const scenes = panoramaImage.scenes
            let firstScene = panoramaImage ?.default ?.firstScene
            if (firstScene) {
                if (scenes[firstScene] ?.audio) {
                    soundToPlay = scenes[firstScene] ?.audio;
                    setAudio(new Audio(soundToPlay))
                }
            }
            //for (var key in scenes) {
            //    soundToPlay = scenes[key].audio;
            //    break
            //}
        }
    }, [panoramaImage, consoleDebug])

    useEffect(() => {
        if (viewer) {
            if (panoramaImage) {
                viewer.on('scenechange', (sceneId) => {
                    if (panoramaImage.scenes[sceneId].audio) {
                        if (panoramaImage.scenes[sceneId].audio == audio) {
                            if (playing) {
                                audio.pause()
                            }
                            setAudio(new Audio(panoramaImage.scenes[sceneId].audio))
                        } else {
                            console.log('Same sound file')
                        }
                    } else {
                        console.log('No audio url')
                    }
                })
                viewer.on('mousedown', function (event) {
                    // coords[0] is pitch, coords[1] is yaw
                    var coords = viewer.mouseEventToCoords(event);
                    // Do something with the coordinates here...
                    if (event.which == 3 && forDebug === 'true') {
                        console.log(event.which)
                        alert(coords)
                    }
                    //if (audio?.src && audio.src != "null") {
                    //    console.log(audio)
                    //    audio.loop = true
                    //    audio.play()
                    //    setPlaying(true)
                    //}
                });
            }
        }
    }, [viewer, audio])

    useEffect(() => {
        if (audio) {
            // console.log(audio)
            audio.loop = true
            audio.play()
                .then(() => {
                    setPlaying(true)
                }).catch(() => {
                    setPlaying(false)
                })
            return () => {
                audio.pause()
                setViewer(null)
            }
        }
    }, [audio])

    return (
        <div>
            <Grid container style={{ position: 'relative' }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div id="panorama" className={classes.panoramaContainer}></div>
                </Grid>
                <div className={classes.overlay}>
                    {false && projectTours ?.floorplans && projectTours.floorplans.map((tour) => (
                        <>
                        <>
                        {
                            false && <Fab size="small" color="primary" onClick={() => window.location.href = '/tour/' + projectTours.id + '/' + tour.tour}>
                                {tour.heading}
                            </Fab>
                        }
                        </>    
                        <Button variant="primary" onClick={() => window.location.href = '/tour/' + projectTours.id + '/' + tour.tour}>{tour.heading}</Button>
                        </>
                    ))
                    }
                    {false && <Fab size="small" color="primary" onClick={handlePlay}>
                        {playing ? <MusicOff /> : <MusicNote />}
                    </Fab>}
                    <Button variant="primary" onClick={handleClick}>Navigate</Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                    {projectTours ?.floorplans && projectTours.floorplans.map((tour) => (
                        <>
                            <>
                                {
                                    false && <Fab size="small" color="primary" onClick={() => window.location.href = '/tour/' + projectTours.id + '/' + tour.tour}>
                                        {tour.heading}
                                    </Fab>
                                }
                            </>
                            <MenuItem onClick={() => window.location.href = '/tour/' + projectTours.id + '/' + tour.tour}>{tour.heading}</MenuItem>
                        </>
                    ))
                    }
                    </Menu>
                    <Button variant="primary" onClick={handlePlay}>{playing ? <MusicOff /> : <MusicNote />}</Button>
                </div>
            </Grid>
        </div>
    )
}

export default Photo360View