import React, { useReducer, useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {ApiContext} from '../../context/ApiContext'
import { CircularProgress, Box, Typography, Container, Grid } from '@material-ui/core'
import Navbar from '../widgets/Navbar'
import Heroarea from '../widgets/Heroarea'
import Large from '../widgets/Large'
import Mini from '../widgets/Mini'
import Carousel from '../widgets/sub_widgets/Carousel'
import Projects from '../widgets/Projects'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import { Form, Button, Breadcrumb, Modal, Card } from 'react-bootstrap'
import Heading from '../widgets/Heading'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(/uploads/images/map_gallery_img_1_CqBW43M.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '720px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative'
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '50px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            bottom: '25px'
        }
    },
    container: {
        marginTop: '90px',
        //[theme.breakpoints.down('sm')]: {
        //    marginTop: '90px',
        //},
        //[theme.breakpoints.down('xs')]: {
        //    marginTop: '90px',
        //}
    },
    heading: {
        fontSize: '2.6rem',
        fontWeight: '500',
        //textAlign: 'center',
        margin: '0px',
        lineHeight: '1em',
        marginTop: '25px',
        marginBottom: '25px',
        marginLeft: '20px',
        letterSpacing: '0.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.0rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8rem'
        }
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '500',
        //letterSpacing: '0.2em',
        fontSize: '1.375rem',
        //'& .sub-title': {
        //    textAlign: 'center'
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem'
        }
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '400',
        //letterSpacing: '0.2em',
        fontSize: '1.1rem',
        '& .sub-title': {
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    gridItem: {
        paddingHorizontal: '20px',
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    card: {
        backgroundColor: 'white',
        color: 'black',
        top: '50%',
    },
}))

const formReducer = (state, event) => {
    if (event.reset) {
        return {
            email: '',
            phone: '',
            name: '',
            password: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

function Login() {
    const classes = useStyles()
    const [formData, setFormData] = useReducer(formReducer, {
    });
    const { submitting, setSubmitting } = useContext(ApiContext)
    const { formError, setFormError } = useContext(ApiContext)

    const { apiToken } = useContext(ApiContext)
    const { postRegisterFormSubmit } = useContext(ApiContext)
    const { postLoginFormSubmit } = useContext(ApiContext)
    const { postLogoutFormSubmit } = useContext(ApiContext)
    const { postUpdateFormSubmit } = useContext(ApiContext)
    const { userData, loadUserData } = useContext(ApiContext)

    const [formMode, setFormMode] = useState("login")
    
    useEffect(() => {
        setFormData({})
        setFormError(null)
    }, [])

    const handleLogin = event => {
        event.preventDefault();
        setSubmitting(-1);
        postLoginFormSubmit(formData)
        //setApiKey(formData);
    }

    const handleUpdate = event => {
        event.preventDefault();
        setSubmitting(-1);
        postUpdateFormSubmit(formData)
        //setApiKey(formData);
    }

    const handleLogout = event => {
        event.preventDefault();
        setSubmitting(-1);
        postLogoutFormSubmit()
        //setApiKey(formData);
    }

    const handleRegister = event => {
        event.preventDefault();
        setSubmitting(-1);
        postRegisterFormSubmit(formData)
        //setApiKey(formData);
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        setFormData({
            name: event.target.name,
            value: isCheckbox ? event.target.checked : event.target.value,
        })
    }

    useEffect(() => {
        console.log(submitting)
    }, [submitting])

    useEffect(() => {
        if (apiToken) {
            loadUserData()
            console.log('Token: ' + apiToken)
            setFormMode("dashboard")
        }
    }, [apiToken])

    useEffect(() => {
        if (userData) {
            console.log('Profile: ' + userData)
            for (const key of Object.keys(userData)) {
                formData[key] = userData[key]
            }
            console.log(formData)
        }
    }, [userData])


    return (
                <Container maxWidth={false} className={classes.container}>
                    {false && <Heading title={formMode == "login" ? 'Login' : 'Register'} />}
                    <Grid container spacing={2} justify='center'>
                        <Grid item xs={12} sm={6} xl={6} lg={6} md={6} className={classes.gridItem}>
                            <Card className={classes.card}>
                                <Card.Body>
                                    <Card.Title></Card.Title>
                                    <Card.Text>
                                    </Card.Text>
                            <Breadcrumb>
                                {apiToken ?
                                    <>
                                        <Breadcrumb.Item active={formMode == "dashboard" ? true : false} onClick={() => setFormMode("dashboard")}>Dashboard</Breadcrumb.Item>
                                        <Breadcrumb.Item active={formMode == "notifications" ? true : false} onClick={() => setFormMode("notifications")}>Notifications</Breadcrumb.Item>
                                        <Breadcrumb.Item active={formMode == "profile" ? true : false} onClick={() => setFormMode("profile")}>Profile</Breadcrumb.Item>
                                    </>
                                    :
                                    <>
                                        <Breadcrumb.Item active={formMode == "login" ? true : false} onClick={() => setFormMode("login")}>Login</Breadcrumb.Item>
                                        <Breadcrumb.Item active={formMode == "register" ? true : false} onClick={() => setFormMode("register")}>Register</Breadcrumb.Item>
                                    </>
                                }
                            </Breadcrumb>
                            <p><Typography className={classes.body}></Typography></p>
                            {false && submitting &&
                                <div>
                                    <p className="text-muted">{Number.isInteger(submitting) && submitting != -1 ? submitting : submitting}</p>
                                {false && <ul>
                                    {Object.entries(formData).map(([name, value]) => (
                                        <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                                    ))}
                                </ul>
                                }
                                </div>
                            }
                            {formError &&
                                <div>
                                    <p className="text-muted">Please correct the following errors:</p>
                                    <ul>
                                    {Object.entries(formError).map(([name, value]) => (
                                            <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {apiToken ?
                                (
                                    <>
                                    <>
                                        {
                                            formMode == "dashboard" && <div>
                                                <p className="text-muted">Logged In as {userData ?.first_name ? userData ?.first_name : '(Not Set)'}</p>
                                                <Form onSubmit={handleLogout}>
                                                    <Button variant="primary" type="submit" disabled={submitting == -1}>
                                                        Logout
                                                    </Button>
                                                </Form>
                                            </div>
                                        }
                                    </>
                                    <>
                                        {formMode == "profile" && <div>
                                            <Form onSubmit={handleUpdate}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange} value={formData.email || ''} disabled={true} />
                                                </Form.Group>
                                                <Form.Group controlId="formBasicFirstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="First Name" name="first_name" onChange={handleChange} value={formData.first_name || ''} />
                                                </Form.Group>
                                                <Form.Group controlId="formBasicLastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Last Name" name="last_name" onChange={handleChange} value={formData.last_name || ''} />
                                                </Form.Group>
                                                <Form.Group controlId="formBasicNumber">
                                                    <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="tel" placeholder="Phone Number" name="phone" onChange={handleChange} value={formData.phone || ''} />
                                                </Form.Group>
                                                <Button variant="primary" type="submit" disabled={submitting == -1}>
                                                    Submit
                                    </Button>
                                            </Form>
                                        </div>
                                    }
                                    </>
                                    </>
                                    )
                                : ( formMode == "login" ?
                                        <Form onSubmit={handleLogin}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange} value={formData.email || ''} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter password" name="password" onChange={handleChange} value={formData.password || ''} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit" disabled={submitting == -1}>
                                                Submit
                                    </Button>
                                        </Form>
                                        :
                                        <Form onSubmit={handleRegister}>
                                            {false && <Form.Group controlId="formBasicName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Name" name="name" onChange={handleChange} value={formData.name || ''} />
                                            </Form.Group>}
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange} value={formData.email || ''} />
                                            </Form.Group>
                                            {false && <Form.Group controlId="formBasicNumber">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter number" name="number" onChange={handleChange} value={formData.number || ''} />
                                            </Form.Group>}
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter password" name="password1" onChange={handleChange} value={formData.password1 || ''} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword2">
                                                <Form.Label>Re-type Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter password" name="password2" onChange={handleChange} value={formData.password2 || ''} />
                                            </Form.Group>
                                            {false && <Form.Text className="text-muted">
                                                We will never share your phone or number with anyone else.
                                    </Form.Text>}
                                            <Button variant="primary" type="submit" disabled={submitting == -1}>
                                                Submit
                                    </Button>
                                        </Form>
                                )
                            }
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
    )
}

export default Login
