import React, { useReducer, useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {ApiContext} from '../../context/ApiContext'
import { CircularProgress, Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Container, Grid } from '@material-ui/core'
import Navbar from '../widgets/Navbar'
import Heroarea from '../widgets/Heroarea'
import Large from '../widgets/Large'
import Mini from '../widgets/Mini'
import Carousel from '../widgets/sub_widgets/Carousel'
import Projects from '../widgets/Projects'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import { Form, Button } from 'react-bootstrap'
import Banner from '../widgets/Banner'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        //backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(/uploads/images/map_gallery_img_1_CqBW43M.jpg)',
        backgroundSize: 'contain',
        height: '100vh',
        //minHeight: '720px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        //position: 'relative'
    },
    container: {
        marginTop: '100px',
        //[theme.breakpoints.down('sm')]: {
        //    marginTop: '90px',
        //},
        //[theme.breakpoints.down('xs')]: {
        //    marginTop: '90px',
        //}
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '50px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            bottom: '25px'
        }
    },
    heading: {
        fontSize: '2.6rem',
        fontWeight: '500',
        //textAlign: 'center',
        margin: '0px',
        lineHeight: '1em',
        marginTop: '25px',
        marginBottom: '25px',
        marginLeft: '20px',
        letterSpacing: '0.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.0rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8rem'
        }
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '500',
        //letterSpacing: '0.2em',
        fontSize: '1.375rem',
        //'& .sub-title': {
        //    textAlign: 'center'
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem'
        }
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '400',
        //letterSpacing: '0.2em',
        fontSize: '1.1rem',
        '& .sub-title': {
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    gridItem: {
        paddingHorizontal: '20px',
    },
    projectsTitle: {
        //fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'left'
    },
}))

function BannerContent() {
    const classes = useStyles()
    const { bannerData, loadBannerData } = useContext(ApiContext)
    const { id } = useParams()
    const { staticLinks, loadStaticLinks } = useContext(ApiContext)

    useEffect(() => {
        if (id != null) {
            loadBannerData(id)
            console.log(bannerData)
        }
    }, [id])
    useEffect(() => {
        if (staticLinks != null) {
            console.log(staticLinks.find(s => s.id == window.location.pathname).page_background)
        }
    }, [])

    return (
        <div className={classes.container}>
                    {bannerData != null && bannerData.links.map((d) => (
                        <Banner height={d.height} title={d.heading} link={d.link != null ? d.link.id : null} background={d.cover} body={d.text} />
                    ))}
                </div>
    )
}

export default BannerContent
