import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase';
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import MyButton from '../../custom_mui_components/MyButton'
import {useHistory} from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    cardRoot: {
        background: 'transparent',
        color: 'white',
        transition: '0.3s',
        '&:hover': {
            background: 'white',
            color: '#222'
        }
    },
    //cardAction: {
    //    paddingBottom: '20px',
    //    display: 'flex',
    //    justifyContent: 'center'
    //},
    cardTitle: {
        color: '#bf9410',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem',
            fontWeight: '400'
        },
    },
    cardSubTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',
            fontWeight: '400'
        },
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    },
    overlay: {
        position: 'absolute',
        bottom: '20px',
        //left: '20px',
        color: 'black',
        backgroundColor: 'white',
        left: 0,
        right: 0,
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'white',
            bottom: '5px',
            height: '40px',
            padding: '5px',
        },
        backgroundColor: 'transparent',
        opacity: '0',
        transition: '0.3s',
        '&:hover': {
            '& + cardSubTitle': {
                color: 'transparent'
            },
            backgroundColor: 'white',
            color: '#222',
            opacity: '1',
        }
    }
}))

function ProjectCard({image, title, subTitle, id}) {
    const classes = useStyles()
    const history = useHistory()
    return (
        <Box paddingLeft={2} paddingRight={2}>
            <Card className={classes.cardRoot}>
                <ButtonBase
                    className={classes.cardAction}
                    onClick={(e) => { history.push('/project/' + id) }}
                >
                    <CardActionArea disableRipple>
                        <CardMedia component="img"
                            alt="image"
                            image={image}
                        />
                        <div className={classes.overlay}>
                            <Typography className={classes.cardTitle} variant="h5" gutterBottom>
                                {title}
                            </Typography>
                                <Typography variant="body2" className={classes.cardSubTitle}>
                                {subTitle}
                            </Typography>
                        </div>
                    </CardActionArea>
                </ButtonBase>
            </Card>
        </Box>
    )
}

export default ProjectCard
