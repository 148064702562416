import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {ApiContext} from '../../context/ApiContext'
import {CircularProgress } from '@material-ui/core'
import Navbar from '../widgets/Navbar'
import Heroarea from '../widgets/Heroarea'
import Large from '../widgets/Large'
import Projects from '../widgets/Projects'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import ProjectCard from '../widgets/sub_widgets/ProjectCard'
import Heading from '../widgets/Heading'

const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '50vh',
        minHeight: '720px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative'
    },
    projectsTitle: {
        fontSize: '2.0rem',
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        //position: 'absolute',
        left: '0',
        right: '0',
        //bottom: '50px',
        paddingBottom: '20px',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            //bottom: '25px'
        }
    },
    heading: {
        fontSize: '2.0rem',
        //textTransform: 'uppercase',
        textAlign: 'center'
    },
}))

function ProjectDetails() {
    const { siteData, dataLoaded, loadProject } = useContext(ApiContext)
    const { id } = useParams()
    /*const loadData = () => {
        loadProject(id).then((data) => {
            console.log(data)
        }).catch(error => {
            console.log(error)
        })
    }*/
    //loadData()
    const classes = useStyles()
    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 2,
        centerMode: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    useEffect(() => {
        console.log("New state")
        loadProject(id)
    }, [])
    if(!siteData){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )
    }
    return (
        <div>
            <Navbar />
            <div>
                <Heroarea heading={siteData.title} subHeading={siteData.description} image={siteData.cover} />
            </div>
            {siteData.large.length > 0 ?
                <Large larges={siteData.large} floorplans={siteData.floorplans} mini={siteData.mini} carousel={siteData.carousel} title={siteData.title} description={siteData.description} writeup={siteData.writeup} logo={siteData.logo} project={siteData.id} model={siteData.model} />
                : <Box mx={4} my={4}><h1 className={classes.projectsTitle} style={{ color: 'black' }}>Coming Soon</h1></Box>}
            {siteData.featured.length > 0 ?
                (
                    <div>
                        {false && <Projects featured={siteData.featured} />}
                        <div>
                            <Box mx={2} my={1}>
                                <h1 className={classes.projectsTitle} style={{ color: 'black' }}>Similar Projects</h1>
                            </Box>
                        <Box className={classes.cardCarouselContainer}>
                            <div>
                                <Slider {...settings}>
                                    {siteData.featured.map((d) => (
                                        <ProjectCard image={d.thumbnail} title={d.title} subTitle={d.description} id={d.id} key={d.id} />
                                    ))}
                        </Slider>
                            </div>
                        </Box>
                    </div>
                    </div>
                    )
                : <></>}
        </div>
    )
}

export default ProjectDetails
