import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect } from 'react'
import Slider from 'react-slick'
import CategoryCard from '../widgets/sub_widgets/CategoryCard'
import { ApiContext } from '../../context/ApiContext'
import Navbar from '../widgets/Navbar'
const useStyles = makeStyles((theme) => ({
    projectCardRoot: {
        // Change the image url to change the background image
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://i.ibb.co/G9B89Tt/map-gallery-img-1.jpg)',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '550px',
        color: 'white',
        overflow: 'hidden',
        maxWidth: '100vw',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
        height: '100vh',
        minHeight: '0',
        }
    },
    projectsTitle: {
        fontSize: theme.spacing(7),
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    colorText: {
        color: '#bf9410'
    },
    cardCarouselContainer: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            top: '40px',
            bottom: '0'
        }
    }
}))


function ProjectCategoryCards() {
    const { categories, loadCategories, loadMoreCategories, categorySlidesToShow } = useContext(ApiContext)
    const classes = useStyles()
    const settings = {
        dots: false,
        arrows: true,
        autoplay: true,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 15000,
        slidesToShow: 2,
        afterChange: (index) => {
            if (index + 3 >= categories.results.length) {
                loadMoreCategories()
            }
        },
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    afterChange: (index) => {
                        if (index + 2 >= categories.results.length) {
                            loadMoreCategories()
                        }
                    },
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    afterChange: (index) => {
                        if (index + 1 >= categories.results.length) {
                            loadMoreCategories()
                        }
                    },
                }
            },
        ]
    };

    useEffect(() => {
        loadCategories()
        document.title = "Portfolio"
        return () => document.title = 'Portfolio'
    }, [])
    return (
        <>
            <Navbar />
            <div className={classes.projectCardRoot}>
                <Box pl={3} pr={3} className={classes.cardCarouselContainer}>
                    <h1 className={classes.projectsTitle}><span className={classes.colorText}>Portfolio</span></h1>
                    <div>
                        {categories != null ?
                            <>
                                <Grid container justify='center'>
                                    <Grid item xl={categorySlidesToShow > 2 ? 10 : 6} lg={categorySlidesToShow > 2 ? 10 : 6} md={categorySlidesToShow > 2 ? 10 : 6} sm={11} xs={11}>
                                        <Slider {...settings} slidesToShow={categorySlidesToShow}>
                                            {categories.results.map((d) => (
                                                <CategoryCard image={d.thumbnail} title={d.heading} subTitle={d.subheading} id={d.id} key={d.id} />
                                            ))}
                                        </Slider>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>}
                    </div>
                </Box>
            </div>
        </>
    )
}

export default ProjectCategoryCards
