import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase';
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import MyButton from '../../custom_mui_components/MyButton'
import {useHistory} from 'react-router-dom'
const useStyles = makeStyles((theme)=>({
    //cardAction: {
    //    paddingBottom: '20px',
    //    display: 'flex',
    //    justifyContent: 'center'
    //},
    cardTitle: {
        color: '#bf9410',
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    }
}))

function CategoryCard({image, title, subTitle, id}) {
    const classes = useStyles()
    const history = useHistory()
    return (
        <Box paddingLeft={2} paddingRight={2}>
            <Card>
                <ButtonBase
                    className={classes.cardAction}
                    onClick={(e) => { history.push('/portfolio/' + id) }}
                >
                    <CardActionArea disableRipple>
                        <CardMedia component="img"
                            alt="image"
                            image={image}
                        />
                        <CardContent>
                            <Typography className={classes.cardTitle} variant="h5" gutterBottom>
                                {title}
                            </Typography>
                            <Typography variant="body2">
                                {subTitle}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </ButtonBase>
            </Card>
        </Box>
    )
}

export default CategoryCard
